import React from 'react';
import Header from "../components/sections/header";
import VideoWords from "../components/sections/video-words";
import BrandLead from "../components/sections/brand-lead";
import History from "../components/sections/history";
import Feature from "../components/sections/feature";
import Footer from "../components/sections/footer";
import DoubleBlock from "../components/sections/double-block";
import {useTranslation} from "react-i18next";
import DamixaBrandImage from "../images/damixa-brand-image.png";
import DamixaHistoryImage from "../images/damixa-brand-image-2.jpg";
import DamixaFeatureImage from "../images/damixa-feature.jpg";
import DoubleDamixaVideo from "../videos/double-damixa.webm";

const Damixa = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header/>
      <BrandLead title={t("brandDamixa.title")} text={t("brandDamixa.leadText")} alt="damixa" image={DamixaBrandImage} />
      <History image={DamixaHistoryImage} alt="ampm history image" text={t("brandDamixa.historyText")} />
      <Feature title={t("brandDamixa.featureTitle")} text={t("brandDamixa.featureText")} image={DamixaFeatureImage} alt="damixa feature" />
      <VideoWords />
      <DoubleBlock text={t("brandDamixa.doubleBlockText")} linkText={t("brandDamixa.doubleBlockLink")} linkHref="https://damixa.com/" video={DoubleDamixaVideo} />
      <Footer/>
    </>
  );
};

export default Damixa;
